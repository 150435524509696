import React from "react";

const SolutionCard = ({ imgSrc, imgAlt, title, description }) => (
  <div className="card mb-3">
    <div className="row no-gutters">
      <div className="col-md-4 d-flex justify-content-center align-items-center">
        <img src={imgSrc} className="card-img" alt={imgAlt} />
      </div>
      <div className="col-md-8">
        <div className="card-body">
          <h5 className="card-title section-title-medium">{title}</h5>
          <p className="card-text">{description}</p>
        </div>
      </div>
    </div>
  </div>
);

export default SolutionCard;
