import React, { useEffect, useRef, useState } from "react";
// import styled from "styled-components";
import ReactFullpage from "@fullpage/react-fullpage";
import { Link } from "gatsby";
import { Container, Image } from "react-bootstrap";
import BaseLayout from "../components/base-layout";
import Footer from "../components/footer";
import Baby from "../images/category/baby.png";
import Body from "../images/category/body.png";
import HairCare from "../images/category/hair-care.png";
import HairStyle from "../images/category/hair-style.png";
import Hand from "../images/category/hand.png";
import Skin from "../images/category/skin.png";
import Aware from "../images/solution/aware.png";
import FixImage from "../images/solution/fix-image.png";
import Idea from "../images/solution/idea.png";
import Legal from "../images/solution/legal.png";
import Market from "../images/solution/market.png";
import PackageImg from "../images/solution/package.png";
import PlanImg from "../images/solution/plan.png";
import PageTitle from "../reusable/page-title";
import SolutionCard from "../reusable/solution-card";

const PRODUCT_LIST = [
  {
    category: "BABY CARE",
    products: {
      left: [
        "Baby Bubble Bath",
        "Baby Oil",
        "Baby Shampoo",
        "Baby Moisturizing Lotion Baby Sunscreen",
        "Nappy Rash Cream",
        "Baby Hair & Body Wash",
        "Baby Hair Lotion",
        "Baby Cologne",
      ],
      right: [],
    },
    image: Baby,
  },
  {
    category: "BODY AND SPA",
    products: {
      left: [
        "Aromatherapy Massage Oil",
        "Body Scrub (salt, sugar, beads)",
        "Body Butter",
        "Body Lotion",
        "Sun Screen",
        "Depilatories / Hair Removal Cream",
        "Shower Gel / Cream / Foam",
      ],
      right: [
        "Anti Bacterial Shower Gel",
        "Insect Repellant Spray",
        "Antiperspirant / Deodorant (roll-on)",
        "Bust and Body Cellulite Firming Gel",
        "Body Mist",
        "Perfume",
      ],
    },
    image: Body,
  },
  {
    category: "HAIR CARE",
    products: {
      left: [
        "Shampoo",
        "Conditioner",
        "Anti-dandruff Shampoo",
        "Antibacterial / Antiseptic Shampoo",
        "2-in-1 Shampoo",
        "Hair Treatment / Hair Mask",
        "Hair Brightening Lotion / Curl Amplifier",
      ],
      right: [
        "Leave-in Conditioning Spray",
        "Hot Oil Treatment",
        "Hair Thermal Protection",
        "Shine Enhancer",
        "Hair Growth Serum / Spray",
        "Hair Mist",
        "Hair Perfume",
      ],
    },
    image: HairCare,
  },
  {
    category: "HAIR STYLING",
    products: {
      left: [
        "Salt Water Spray",
        "Hair Lotion",
        "Hair Styling Gel",
        "Hair Molding Cream / Fiber Cream",
        "Hair Mud",
      ],
      right: [
        "Hair Pomade",
        "Hair Wax",
        "Hair Styling Spray (No aerosol)",
        "Sparkling Gel",
        "Hair Foam",
      ],
    },
    image: HairStyle,
  },
  {
    category: "HAND AND FEET",
    products: {
      left: [
        "Hand and Nail Cream",
        "Hand Mask Treatment",
        "Cuticle Oil",
        "Urea Skin Softener",
        "Aloe Vera Gel / After Sun and Sensitive Skin Care",
      ],
      right: [
        "Anti-bacterial Sanitizer Gel (Raise free)",
        "Anti-bacterial Hand Wash / Foam",
        "Foot Soak - Anti-bacterial, Anti-fungal, Antiseptic",
        "Foot Cooling Gel",
        "Massage Oil",
        "Foot Massage Cream",
      ],
    },
    image: Hand,
  },
  {
    category: "SKIN CARE",
    products: {
      left: [
        "Cleansing Milk",
        "Cleansing Gel",
        "Cleansing Foam",
        "Facial Wash",
        "Eye Makeup Remover",
        "Makeup Remover Oil",
        "Micellar Water",
        "Face Scrub (With micro beads)",
        "Gammage Exfoliant",
        "Massage Cream",
        "Floral Water / Toner / Astringent Lotion",
        "Lip Balm",
        "Lip Scrub",
      ],
      right: [
        "Facial Mask",
        "Powder Mask",
        "Peel-off Mask",
        "Moisturizing Gel / Lotion / Cream",
        "Moisturizing Lotion with Sun Protection",
        "Anti-aging Cream",
        "Essence and Serum",
        "Paper / Sheet Mask",
        "Clay / Mud Mask",
        "Hydrating Gel Mask",
        "Soothing Gel",
        "Eye Cream",
        "Pimple / Acne Gel",
      ],
    },
    image: Skin,
  },
];

const LabPage = ({ uri, location }) => {
  // const [categoryOption, setCategoryOption] = useState("");
  const ref = useRef(null);
  const [fpPaddingTop, setFpPaddingTop] = useState(0);
  const [fpPaddingBottom, setFpPaddingBottom] = useState(0);
  const [useNavigation, setUseNavigation] = useState(true);

  useEffect(() => {
    if (
      location.hash === "" &&
      location.pathname === "/services" &&
      typeof window !== "undefined" &&
      ref.current
    ) {
      ref.current.fullpageApi.moveTo();
    }
  }, [location, ref]);

  useEffect(() => {
    var categories = "";
    if (typeof window !== `undefined` && window.innerWidth < 991) {
      setFpPaddingTop("86px");
      setFpPaddingBottom("5em");
      setUseNavigation(false);
    }

    PRODUCT_LIST.forEach((item, index) => {
      // console.log(index);
      let tempHandler;

      if (parseInt(index) === 5) {
        tempHandler =
          '<option selected value="' +
          index +
          '">' +
          item.category +
          "</option>";
      } else {
        tempHandler =
          '<option value="' + index + '">' + item.category + "</option>";
      }

      categories = categories.concat(tempHandler);
    });

    let leftProductList = "";
    PRODUCT_LIST[5].products.left.forEach((item, index) => {
      let holder =
        '<div class="product-wrapper d-flex justify-content-start align-items-center"><svg height="15" width="15"><polygon points="0,0 0,15 15,7.5" class="right-triangle" />Sorry, your browser does not support inline SVG.</svg><span>' +
        item +
        "</span></div>";

      leftProductList = leftProductList.concat(holder);
    });

    let rightProductList = "";
    PRODUCT_LIST[5].products.right.forEach((item, index) => {
      let holder =
        '<div class="product-wrapper d-flex justify-content-start align-items-center"><svg height="15" width="15"><polygon points="0,0 0,15 15,7.5" class="right-triangle" />Sorry, your browser does not support inline SVG.</svg><span>' +
        item +
        "</span></div>";

      rightProductList = rightProductList.concat(holder);
    });

    document.getElementById("category-selector").innerHTML = categories;
    document.getElementById("left-product-list").innerHTML = leftProductList;
    document.getElementById("right-product-list").innerHTML = rightProductList;
    document.getElementById("product-thumbnail").innerHTML =
      '<Image class="slideintop category-illustration" src="' +
      PRODUCT_LIST[0].image +
      '" alt="wki product illustrations" fluid />';
  }, []);

  const onCategoryChange = () => {
    let categoryId = document.getElementById("category-selector").value;

    let newLeftProductList = "";
    PRODUCT_LIST[categoryId].products.left.forEach((item, index) => {
      let holder =
        '<div class="product-wrapper d-flex justify-content-start align-items-center"><svg height="15" width="15"><polygon points="0,0 0,15 15,7.5" class="right-triangle" />Sorry, your browser does not support inline SVG.</svg><span>' +
        item +
        "</span></div>";

      newLeftProductList = newLeftProductList.concat(holder);
    });

    let newRightProductList = "";
    PRODUCT_LIST[categoryId].products.right.forEach((item, index) => {
      let holder =
        '<div class="product-wrapper d-flex justify-content-start align-items-center"><svg height="15" width="15"><polygon points="0,0 0,15 15,7.5" class="right-triangle" />Sorry, your browser does not support inline SVG.</svg><span>' +
        item +
        "</span></div>";

      newRightProductList = newRightProductList.concat(holder);
    });

    document.getElementById("left-product-list").innerHTML = newLeftProductList;
    document.getElementById("right-product-list").innerHTML =
      newRightProductList;
    document.getElementById("product-thumbnail").innerHTML =
      '<Image class="slideintop category-illustration" src="' +
      PRODUCT_LIST[categoryId].image +
      '" alt="wki product illustrations" fluid />';
  };

  return (
    <BaseLayout navColor='white' withFooter={false}>
      <ReactFullpage
        ref={ref}
        // autoScrolling={true}
        scrollOverflow={true}
        anchors={["home", "innovation", "reasons", "about", "footer"]}
        paddingTop={fpPaddingTop}
        paddingBottom={fpPaddingBottom}
        scrollingSpeed={1200}
        navigation={useNavigation}
        responsiveHeight={640}
        verticalCentered={true}
        render={({ fullpageApi }) => {
          if (
            typeof window !== `undefined` &&
            window.innerWidth < 991 &&
            fullpageApi
          ) {
            console.log(fullpageApi);
            fullpageApi.destroy("all");
          }

          return (
            <ReactFullpage.Wrapper>
              <section
                className='fifth-section section'
                data-anchor='solutions'>
                <PageTitle title='Our Solution' />

                <div id='fifth-bg'></div>
                <Container className='img-solutions'>
                  <Image
                    className='position-relative'
                    src={FixImage}
                    alt='wki solutions illustration'
                    fluid
                  />
                </Container>

                <div className='container html-solutions'>
                  <div className='row position-relative solution-row gradient-bottom-border'>
                    <div className='col-sm-6'>
                      <SolutionCard
                        imgSrc={Idea}
                        imgAlt='wki develop the idea illustration'
                        title='1. Develop the idea'
                        description='Kami mengembangkan ide Anda dengan konsep yang terarah serta memberikan rekomendasi bahan - bahan yang cocok digunakan untuk produk Anda.'
                      />
                    </div>

                    <div className='col-sm-6'>
                      <SolutionCard
                        imgSrc={PlanImg}
                        imgAlt='wki create the time plan illustration'
                        title='2. Create the time plan'
                        description='Kami bantu menyusun time plan yang jelas dan rinci tepat waktu.'
                      />
                    </div>
                  </div>

                  <div className='row flex-sm-row-reverse position-relative solution-row gradient-bottom-border gradient-right-border'>
                    <div className='col-sm-6'>
                      <SolutionCard
                        imgSrc={Legal}
                        imgAlt='wki Legality illustration'
                        title='3. Legality'
                        description='Kami bantu untuk pengurusan hak paten merk dan dokumen legalitas lainnya yang berkaitan dengan produk.'
                      />
                    </div>
                    <div className='col-sm-6'>
                      <SolutionCard
                        imgSrc={PackageImg}
                        imgAlt='wki Packaging concept illustration'
                        title='4. Packaging concept'
                        description='Kami percaya bahwa ketertarikan konsumen pertama kali
                        dari kemasan produk, maka kami memberi perhatian lebih
                        terhadap kemasan cantik untuk produk spesial Anda.'
                      />
                    </div>
                  </div>

                  <div className='row position-relative solution-row gradient-bottom-border gradient-left-border'>
                    <div className='col-sm-6'>
                      <SolutionCard
                        imgSrc={Market}
                        imgAlt='wki Recommendation market illustration'
                        title='5. Recommendation market'
                        description='Kami memberikan rekomendasi pasar yang tepat bagi produk
                        Anda, sehingga hasil yang diperoleh pun maksimal.'
                      />
                    </div>

                    <div className='col-sm-6'>
                      <SolutionCard
                        imgSrc={Aware}
                        imgAlt='wki Awareness of brand illustration'
                        title='6. Awareness of brand'
                        description='Kami bantu konsep branding yang tepat untuk meningkatkan engagement konsumen terhadap produk anda.'
                      />
                    </div>
                  </div>
                </div>
              </section>

              <section
                className='sixth-section section mb-5'
                data-anchor='products'>
                <PageTitle title='Product List' noMarginTop />
                <div className='container'>
                  <div className='row'>
                    <div className='col-sm-9'>
                      <div id='product-category' className='form-group mb-5'>
                        <label htmlFor='category-selector'>
                          Choose category :{" "}
                        </label>
                        <select
                          className='custom-select form-control ml-3'
                          id='category-selector'
                          onChange={() => onCategoryChange()}>
                          <option value='0'>-</option>
                        </select>
                      </div>

                      <div className='row slideleft'>
                        <div className='col-6'>
                          <div
                            className='left-product-list'
                            id='left-product-list'></div>
                        </div>
                        <div className='col-6'>
                          <div
                            className='right-product-list'
                            id='right-product-list'></div>
                        </div>
                      </div>
                    </div>
                    <div
                      className='col-sm-3 product-thumbnail'
                      id='product-thumbnail'></div>
                  </div>
                  <div style={{ paddingTop: 16 }}>
                    <Link to='/contact-us' style={{ fontSize: 14 }}>
                      Not here? ask us to customize your product
                    </Link>
                  </div>
                </div>
              </section>

              <Footer />
            </ReactFullpage.Wrapper>
          );
        }}
      />
      <React.Fragment></React.Fragment>
    </BaseLayout>
  );
};

export default LabPage;
